<template>
  <h1 class="content-title"> {{ $t('neuralNetwork') }} </h1>
  <div class="mb-4 mt-4">
    <p>
      Un réseau de neurones (réseau neuronal ou réseau de neurones artificiels) est un modèle de transformation de
      données servant principalement à extraire des patrons (patterns) grâce à un apprentissage fait à partir d’un
      ensemble de données fournies en entrée (input data).
    </p>
    <p>
      Les réseaux de neurones sont une inspiration approximative des réseaux de neurones biologiques de structure et de
      fonctionnement partiellement semblables. À l’instar des réseaux de neurones biologiques, un réseau de neurones
      artificiels est un algorithme informatique (ANN – Artificial Neural Network) constitué d’un ensemble d’unités
      liées entre elles (neurones) parmi lesquelles les informations sont propagées afin d’activer une fonction
      particulière à la suite de sa réponse.
    </p>
    <p>
      L’intelligence artificielle réfère aux programmes informatiques pouvant mimer la capacité de discernement dont les
      êtres biologiques sont dotés. Tout comme les êtres vivants, ces programmes ont besoin d’être équipés d’une
      capacité d’apprentissage qui leur permettra d’améliorer leur performance au fur et à mesure qu’ils progressent
      dans leur apprentissage. Le but de l’apprentissage machine auquel ce concept fait allusion est de créer des
      modèles apprenants capables d’apprendre à exécuter des tâches spécifiques (classification d’objets, détection
      d’anomalies, etc.) en se basant généralement sur des observations historiques.
    </p>
    <p>
      En apprentissage machine, plusieurs approches permettent d’élaborer un modèle apprenant; les réseaux de neurones
      constituent une méthode de choix pour obtenir des modèles performants et efficaces pour le traitement de données
      visuelles, textuelles, sonores ou des séries temporelles.
    </p>
    <p>
      La création de réseaux de neurones artificiels passe souvent par l’utilisation de certaines librairies
      d’apprentissage machine compatibles avec les langages de programmation les plus utilisées comme Python, R, Java ou
      Go. Ces librairies permettent aux développeurs de faire abstraction des complexités liées à la création des
      parties élémentaires du réseau, de l’utilisation des moteurs d’accélération graphiques tels que Cuda pour les
      cartes graphiques de type Nvidia, et de bien d’autres fonctionnalités.
    </p>
    <p>
      Les librairies les plus populaires sont
      <a href="https://www.tensorflow.org/?hl=fr" target="_blank">TensorFlow</a>,
      <a href="https://pytorch.org/" target="_blank">PyTorch</a> et
      <a href="https://keras.io/" target="_blank">Keras</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: "NeuralNetwork"
}
</script>

<style scoped>

</style>