<template>
  <h5 class="mt-4">Des exemples de synthétiseur voix-texte</h5>
  <Modal :title="'Speech-to-text issue'" :body="'An issue occured. If you are on an IOS device, make sure to enable Siri to try this feature'" :needs-validation="false"></Modal>
  <div class="accordion mb-4" id="accordionSTT">
    <div class="accordion-item sttContent" v-if="isSupported">
      <h2 class="accordion-header" id="headingOneSTT">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneSTT" aria-expanded="false" aria-controls="collapseOneSTT">
          Web API
        </button>
      </h2>
      <div id="collapseOneSTT" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingOneSTT" data-bs-parent="#accordionSTT">
        <div class="accordion-body">
          <div class="container pt-2">
            <div id="div_language">
              <p v-if="isSafariBrowser" class="sttNotes">Pour utiliser cette fonctionnalité sur Safari, vous devez activer Siri pour la reconnaissance vocale.</p>
              <h5 class="mb-3">Langue</h5>
              <select class="form-select" id="select_language" v-model="currentLanguageIndex">
                <option v-for="(lang, index) in langs" :key="index" :value="index">{{ lang[0] }}</option>
              </select>
              <select class="form-select mt-2" id="select_dialect" v-model="currentDialectIndex">
                <option v-for="(dialect, index) in langs[currentLanguageIndex].slice(1)" :key="index" :value="index">{{ dialect[0] }}</option>
              </select>
            </div>
            <h5 class="mt-4">Transcription</h5>
            <div class="p-3" style="border: 1px solid gray; height: 300px; border-radius: 8px;">
              <span id="final" style="color: red"></span>
              <span id="interim" style="color: blue"></span>
            </div>
            <div class="mt-4">
              <div class="d-flex justify-content-center">
                <button class="btn btn-success me-2" id="start" @click="startListening">Start</button>
                <button class="btn btn-danger" id="stop" @click="stopListening">Stop</button>
              </div>
              <div class="d-flex justify-content-center">
                <p id="status" class="mt-3" style="display: none">A l'écoute...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="needLinks" class="accordion-item">
      <h2 class="accordion-header" id="headingThreeSTT">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeSTT" aria-expanded="false" aria-controls="collapseThreeSTT">
          IBM Watson
        </button>
      </h2>
      <div id="collapseThreeSTT" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingThreeSTT" data-bs-parent="#accordionSTT">
        <div class="accordion-body">
          <iframe src="https://www.ibm.com/demos/live/speech-to-text/self-service/home" width="100%" height="300"></iframe>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingSixSTT">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixSTT" aria-expanded="false" aria-controls="collapseSixSTT">
          Dictation
        </button>
      </h2>
      <div id="collapseSixSTT" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingSixSTT" data-bs-parent="#accordionSTT">
        <div class="accordion-body">
          <iframe src="https://dictation.io/speech" width="100%" height="300" allow="microphone *"></iframe>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Modal from "@/components/Modal";
import {mapGetters} from "vuex";
export default {
  name: "stt",
  components: {Modal},
  data() {
    return {
      langs: [['Afrikaans',       ['af-ZA']],
        ['Bahasa Indonesia',['id-ID']],
        ['Bahasa Melayu',   ['ms-MY']],
        ['Català',          ['ca-ES']],
        ['Čeština',         ['cs-CZ']],
        ['Deutsch',         ['de-DE']],
        ['English',         ['en-AU', 'Australia'],
          ['en-CA', 'Canada'],
          ['en-IN', 'India'],
          ['en-NZ', 'New Zealand'],
          ['en-ZA', 'South Africa'],
          ['en-GB', 'United Kingdom'],
          ['en-US', 'United States']],
        ['Español',         ['es-AR', 'Argentina'],
          ['es-BO', 'Bolivia'],
          ['es-CL', 'Chile'],
          ['es-CO', 'Colombia'],
          ['es-CR', 'Costa Rica'],
          ['es-EC', 'Ecuador'],
          ['es-SV', 'El Salvador'],
          ['es-ES', 'España'],
          ['es-US', 'Estados Unidos'],
          ['es-GT', 'Guatemala'],
          ['es-HN', 'Honduras'],
          ['es-MX', 'México'],
          ['es-NI', 'Nicaragua'],
          ['es-PA', 'Panamá'],
          ['es-PY', 'Paraguay'],
          ['es-PE', 'Perú'],
          ['es-PR', 'Puerto Rico'],
          ['es-DO', 'República Dominicana'],
          ['es-UY', 'Uruguay'],
          ['es-VE', 'Venezuela']],
        ['Euskara',         ['eu-ES']],
        ['Français',        ['fr-FR']],
        ['Galego',          ['gl-ES']],
        ['Hrvatski',        ['hr_HR']],
        ['IsiZulu',         ['zu-ZA']],
        ['Íslenska',        ['is-IS']],
        ['Italiano',        ['it-IT', 'Italia'],
          ['it-CH', 'Svizzera']],
        ['Magyar',          ['hu-HU']],
        ['Nederlands',      ['nl-NL']],
        ['Norsk bokmål',    ['nb-NO']],
        ['Polski',          ['pl-PL']],
        ['Português',       ['pt-BR', 'Brasil'],
          ['pt-PT', 'Portugal']],
        ['Română',          ['ro-RO']],
        ['Slovenčina',      ['sk-SK']],
        ['Suomi',           ['fi-FI']],
        ['Svenska',         ['sv-SE']],
        ['Türkçe',          ['tr-TR']],
        ['български',       ['bg-BG']],
        ['Pусский',         ['ru-RU']],
        ['Српски',          ['sr-RS']],
        ['한국어',            ['ko-KR']],
        ['中文',             ['cmn-Hans-CN', '普通话 (中国大陆)'],
          ['cmn-Hans-HK', '普通话 (香港)'],
          ['cmn-Hant-TW', '中文 (台灣)'],
          ['yue-Hant-HK', '粵語 (香港)']],
        ['日本語',           ['ja-JP']],
        ['Lingua latīna',   ['la']]],
      currentLanguageIndex: 6,
      currentDialectIndex: 0,
      speechRecognition: null,
      isSafariBrowser: false,
      isSupported: false
    }
  },
  methods: {
    startListening() {
      document.querySelector("#status").style.display = "block";
      this.speechRecognition.start();

    },
    stopListening() {
      document.querySelector("#status").style.display = "none";
      this.speechRecognition.stop();
    }
  },
  computed: {
    ...mapGetters(['getTheme']),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
    needLinks() {
      return window.matchMedia('(min-width: 768px)').matches;
    }
  },
  mounted() {
    let userAgent = navigator.userAgent;
    // Need to detect edge first as it has chromium but no support for speechRecognition
    if(userAgent.match(/edg/i)){
      this.isSupported = false;
    } else if(userAgent.match(/chrome|chromium|crios/i)){
      this.isSupported = true;
    } else if(userAgent.match(/safari/i)){
      this.isSupported = true;
      this.isSafariBrowser= true;
    } else{
      this.isSupported = false;
    }

    if(this.isSupported) {
      let SpeechRecognition = window.webkitSpeechRecognition;
      let recognition = new SpeechRecognition();
      recognition.continuous = true
      recognition.interimResults = true;
      recognition.lang = this.langs[this.currentLanguageIndex][this.currentDialectIndex]

      let final_transcript = "";

      recognition.onerror = () => {
        // Hide the Status Element
        document.querySelector("#status").style.display = "none";
        window.$('#modal').modal('toggle')
      };

      recognition.onend = () => {
        // Hide the Status Element
        document.querySelector("#status").style.display = "none";
      };

      recognition.onresult = (event) => {
        // Create the interim transcript string locally because we don't want it to persist like final transcript
        let interim_transcript = "";

        // Loop through the results from the speech recognition object.
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          // If the result item is Final, add it to Final Transcript, Else add it to Interim transcript
          if (event.results[i].isFinal) {
            final_transcript += event.results[i][0].transcript;
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }

        // Set the Final franscript and Interim transcript.
        document.querySelector("#final").innerHTML = final_transcript;
        document.querySelector("#interim").innerHTML = interim_transcript;
      };
      this.speechRecognition = recognition;
    }
  }
}
</script>

<style scoped>

.sttContent {
  text-align: left;
}

.sttButtons {
  text-align: center;
}

.sttNotes {
  font-style: italic;
  color: rgba(var(--on-background-color), 0.4);
  font-size: 0.75rem;
}

</style>