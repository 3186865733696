<template>
  <h5 class="mt-4">Des exemples de synthétiseur texte-voix</h5>
  <div class="accordion" id="accordionTTS">
    <div class="accordion-item">
      <h2 class="accordion-header" id="accordionTTSHeadingOne">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#accordionTTSCollapseOne" aria-expanded="false" aria-controls="accordionTTSCollapseOne">
          Web API
        </button>
      </h2>
      <div id="accordionTTSCollapseOne" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="accordionTTSHeadingOne" data-bs-parent="#accordionTTS">
        <div class="accordion-body">
          <div class="mb-3">
            <label for="inputMessage" class="form-label">Text to speech</label>
            <textarea class="form-control" id="inputMessage" rows="5" v-model="message" placeholder="Texte à synthétiser vocalement"></textarea>
          </div>
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="mb-3 row">
                  <label for="inputLanguage" class="col-sm-4 col-form-label">Langue</label>
                  <div class="col-sm-8">
                    <select class="form-select" id="inputLanguage" v-model="language">
                      <option v-for="locale in languageList" :key="locale.lang">{{ locale.lang }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mb-3 row">
                  <label for="inputPitch" class="col-sm-4 col-form-label">Hauteur tonale</label>
                  <input type="range" class="col-sm-8 col-form-range" min="0" max="2" step="0.1" id="inputPitch" v-model="pitch">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="mb-3 row">
                  <label for="inputRate" class="col-sm-4 col-form-label">Vitesse du débit</label>
                  <input type="range" class="col-sm-8 col-form-range" min="0" max="1.5" step="0.05" id="inputRate" v-model="rate">
                </div>
              </div>
              <div class="col">
                <div class="mb-3 row">
                  <label for="inputVolume" class="col-sm-4 col-form-label">Volume</label>
                  <input type="range" class="col-sm-8 col-form-range" min="0" max="1" step="0.05" id="inputVolume" v-model="volume">
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button class="btn btn-success me-2" @click="speak()"><font-awesome-icon :icon="currentIcon" style="color: var(--on-background-color);"/> {{ currentButtonText }}</button>
            <button class="btn btn-danger" @click="cancel()"> Annuler </button>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="accordionTTSHeadingFour">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#accordionTTSCollapseFour" aria-expanded="false" aria-controls="accordionTTSCollapseFour">
          Amazon
        </button>
      </h2>
      <div id="accordionTTSCollapseFour" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="accordionTTSHeadingFour" data-bs-parent="#accordionTTS">
        <div class="accordion-body">
          <iframe src="https://ai-service-demos.go-aws.com/polly" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlay, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import {mapGetters} from "vuex";

library.add({ faPlay, faVolumeUp })

export default {
  name: "tts",
  data() {
    return {
      message: '',
      pitch: 1,
      rate: 0.75,
      volume: 0.5,
      language: "en-US",
      currentIcon: 'play',
      currentButtonText: 'Parler!',
      languageList: null,
      synth: window.speechSynthesis,
      isPaused: false
    }
  },
  methods: {
    speak(){
      if(this.synth.speaking) {
        if(this.isPaused) {
          this.updateCurrentIconAndText('volume-up', '');
          this.isPaused = false;
          this.synth.resume()
        } else {
          this.updateCurrentIconAndText('play', 'Resume');
          this.isPaused = true;
          this.synth.pause()
        }
      } else {
        let utterThis = new SpeechSynthesisUtterance(this.message);
        utterThis.rate = this.rate;
        utterThis.pitch = this.pitch;
        utterThis.volume = this.volume;
        utterThis.lang = this.language;
        utterThis.onstart = this.updateCurrentIconAndText('volume-up', '');
        utterThis.onend = (function() {
          this[0].updateCurrentIconAndText('play', 'Speak it!');
        }).bind(window.$(this));
        this.synth.speak(utterThis);
      }
    },
    pause(){
      this.synth.pause()
    },
    resume(){
      this.synth.resume()
    },
    cancel(){
      this.synth.cancel();
    },
    updateCurrentIconAndText(icon, text) {
      this.currentIcon = icon;
      this.currentButtonText = text;
    },
  },
  computed: {
    ...mapGetters(['getTheme']),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
  },
  mounted() {
    this.languageList = this.synth.getVoices()
    this.synth.onvoiceschanged = () => {
      this.languageList = this.synth.getVoices()
    }
  }
}
</script>

<style>

div>iframe>html>body>div.view>div.app {
  overflow-x: auto !important;
}

</style>