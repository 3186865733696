import { store } from "@/store";
import rangy from "rangy/lib/rangy-serializer";

export default {
    highlight(routeName, color, comment, id = null, selection = null, forUpdate = null) {
        let range = selection == null ? window.getSelection().getRangeAt(0) : selection;

        const {
            commonAncestorContainer,
            startContainer,
            endContainer,
            startOffset,
            endOffset,
        } = range;
        const nodes = [];

        if(forUpdate) {
            let currentHighLightValue = store.getters.getHighlight(id)
            if(document.getElementById(this.highlightId(id)) !== null) {
                if(currentHighLightValue.comment !== comment) {
                    this.appendCommentIconToHighlight(comment, id)
                }
            } else {
                let  i = 0;
                do {
                    document.getElementById(this.highlightId(id, i)).className = "highlight-" + color;
                    i = i+1;
                }
                while (document.getElementById(this.highlightId(id, i)) !== null)
                if(currentHighLightValue.comment !== comment) {
                    this.appendCommentIconToHighlight(comment, id, i-1)
                }
            }
            store.commit("updateHighlight", {
                highlight: store.getters.getHighlight(id),
                color: color,
                comment: comment
            })
            return;
        }

        if (startContainer === endContainer) {
            let formattedStartingIndex = this.getPreviousWordOffset(range.startContainer.nodeValue, startOffset);
            let formattedEndingIndex = this.getNextWordOffset(range.endContainer.nodeValue, endOffset);
            this.formatIndexAndAddToStore(range, formattedStartingIndex, formattedEndingIndex, selection, forUpdate, routeName, color, comment)

            const span = document.createElement("span");
            span.className = "highlight-" + color;
            span.id=this.highlightId(id === null ? store.getters.getHighlightList.length - 1 : id);
            range.surroundContents(span);
            if(comment !== null && comment !== '') {
                this.appendCommentIconToHighlight(comment, id)
            }
            return;
        }

        const checkedIndexes = []

        let formattedStartingIndex = this.getPreviousWordOffset(range.startContainer.nodeValue, startOffset);
        let formattedEndingIndex = this.getNextWordOffset(range.endContainer.nodeValue, endOffset);

        this.formatIndexAndAddToStore(range, formattedStartingIndex, formattedEndingIndex, selection, forUpdate, routeName, color, comment)
        this.getNodesInCommonAncestor(commonAncestorContainer.childNodes, nodes, range, checkedIndexes, formattedStartingIndex);
        let i = 0;
        this.highlightNodes(nodes, i, formattedStartingIndex, formattedEndingIndex, comment, id, color);

        if(range.nativeRange) {
            window.getSelection().removeRange(range.nativeRange);
        }
        else {
            window.getSelection().removeRange(range);
        }
    },
    formatIndexAndAddToStore(range, formattedStartingIndex, formattedEndingIndex, selection, forUpdate, routeName, color, comment) {
        range.setStart(range.startContainer, formattedStartingIndex + 1)
        range.setEnd(range.endContainer, formattedEndingIndex)

        // this.getNodesInCommonAncestor(commonAncestorContainer.childNodes, nodes, range, checkedIndexes, startOffset, endOffset, diffBefore, diffAfter);
        if(selection === null && !forUpdate) {
            store.commit("addElementToHighlightList", {
                route: routeName,
                range: rangy.serializeRange(range, true),
                id: store.getters.getHighlightList.length,
                color: color,
                content: range.toString(),
                comment: comment
            })
        }
    },
    highlightNodes(nodes, i, startOffset, endOffset, comment, id, color) {
        nodes.forEach((node, index, listObj) => {
            const { nodeValue } = node;
            let text, prevText, nextText;
            if (index === 0) {
                prevText = nodeValue.substring(0, startOffset + 1);
                text = nodeValue.substring(startOffset + 1);
            } else if (index === listObj.length - 1) {
                text = nodeValue.substring(0, endOffset);
                nextText = nodeValue.substring(endOffset);
            } else {
                text = nodeValue;
            }

            const span = document.createElement("span");
            span.className = "highlight-" + color;
            span.id=this.highlightId(id === null ? store.getters.getHighlightList.length - 1 : id, i);
            span.append(document.createTextNode(text));

            const { parentNode } = node;

            parentNode.replaceChild(span, node);

            if (prevText) {
                const prevDOM = document.createTextNode(prevText);
                span.id=this.highlightId(id === null ? store.getters.getHighlightList.length - 1 : id, i);
                parentNode.insertBefore(prevDOM, span);
            }

            if (nextText) {
                const nextDOM = document.createTextNode(nextText);
                span.id=this.highlightId(id === null ? store.getters.getHighlightList.length - 1 : id, i);
                parentNode.insertBefore(nextDOM, span.nextSibling);
                this.appendCommentIconToHighlight(comment, id, i)
            }
            i = ++i
        });
    },
    getNodesInCommonAncestor(childList, nodes, range, checkedIndexes, formattedStartOffset) {
        let rangeArray = range.toString().split(" ");
        this.removeBlanks(rangeArray);

        childList.forEach((node) => {
            const nodeValue = node.nodeValue;
            if (node.nodeType === 3 && nodeValue.replace(/^\s+|\s+$/gm, "") !== "") {
                let nodeValueArray = nodeValue.toString().split(" ");
                this.removeBlanks(nodeValueArray);
                let isDoneCheckingForNode = false

                for(let  i = 0; i < nodeValueArray.length; i++) {
                    if(nodeValueArray[i].includes(rangeArray[checkedIndexes.length])) {
                        let initialIndexFound = i;
                        let j = checkedIndexes.length
                         do {
                             if(i === nodeValueArray.length || j === rangeArray.length - 1) {
                                 i = i + 1
                                 j = j + 1
                                 isDoneCheckingForNode = true
                                 break;
                             }
                            if(nodeValueArray[i + 1] === undefined || rangeArray[j + 1] === undefined) {
                                i = i + 1
                                j = j + 1
                                isDoneCheckingForNode = true
                                break;
                            }
                            i = i + 1
                            j = j + 1
                        } while(nodeValueArray[i].includes(rangeArray[j]))
                        if(isDoneCheckingForNode) {
                            let maxCounter = nodeValueArray.length > (initialIndexFound + j) - checkedIndexes.length ? (initialIndexFound + j) - checkedIndexes.length : nodeValueArray.length
                            for(let counter = initialIndexFound; counter < maxCounter; counter ++) {
                                checkedIndexes.push(nodeValueArray[counter])
                            }
                            console.log(nodes)
                            nodes.push(node)
                            break;
                        }
                    }
                }

                if(rangeArray.length === checkedIndexes.length) {
                    return;
                }
            }
            if (node.nodeType === 1) {
                if(!(node.className instanceof SVGAnimatedString) && node.className.includes("highlight")) {
                    let content = document.getElementById(node.id).innerText
                    let nodeValueArray = content.toString().split(" ");
                    this.removeBlanks(nodeValueArray);
                    let isDoneCheckingForNode = false

                    for(let  i = 0; i < nodeValueArray.length; i++) {
                        if(nodeValueArray[i] === rangeArray[checkedIndexes.length]) {
                            let initialIndexFound = i;
                            let j = checkedIndexes.length
                            do {
                                if(i === nodeValueArray.length || j === rangeArray.length - 1) {
                                    i = i + 1
                                    j = j + 1
                                    isDoneCheckingForNode = true
                                    break;
                                }
                                if(nodeValueArray[i + 1] === undefined || rangeArray[j + 1] === undefined) {
                                    i = i + 1
                                    j = j + 1
                                    isDoneCheckingForNode = true
                                    break;
                                }
                                i = i + 1
                                j = j + 1
                            } while(nodeValueArray[i] === rangeArray[j])
                            if(isDoneCheckingForNode) {
                                let maxCounter = nodeValueArray.length > (initialIndexFound + j) - checkedIndexes.length ? (initialIndexFound + j) - checkedIndexes.length : nodeValueArray.length
                                for(let counter = initialIndexFound; counter < maxCounter; counter ++) {
                                    checkedIndexes.push(nodeValueArray[counter])
                                }
                                break;
                            }
                        }
                    }
                }
                 else if(!(node.className instanceof SVGAnimatedString) && node.childNodes && node.className === '' || !(node.className instanceof SVGAnimatedString) && !node.className.includes("highlight")) {
                    // add checkedIndex when class highlight
                    this.getNodesInCommonAncestor(node.childNodes, nodes, range, checkedIndexes, formattedStartOffset);
                }
            }
        });
    },
    getPreviousWordOffset(nodeValue, startOffset) {
        let i = startOffset;
        if(startOffset === null || nodeValue === null) {
            return startOffset
        }
        if(nodeValue[i] === ' ' && nodeValue[i] === undefined) {
            return i + 1
        }
        while(nodeValue[i] !== ' ' && nodeValue[i] !== undefined) {
            i = i - 1;
        }
        return i
    },
    getNextWordOffset(nodeValue, endOffset) {
        let i = endOffset > 0 ? endOffset - 1 : endOffset;
        if(i === null || nodeValue === null) {
            return endOffset
        }
        if(nodeValue[i] === ' ' && nodeValue[i] === undefined) {
            return i - 1
        }
        while(nodeValue[i] !== ' ' && nodeValue[i] !== undefined) {
            i = i + 1;
        }
        return i
    },
    removeBlanks(array) {
        let i = array.length;
        while (i--) {
            if (array[i] === '') {
                array.splice(array.indexOf(''), 1);
            }
        }
    },
    highlightId(baseIndex, secondIndex = null) {
        if(secondIndex === null) {
            return "highlight-" + baseIndex;
        }
        return "highlight-" + baseIndex + "." + secondIndex;
    },
    appendCommentIconToHighlight(comment, index, secondIndex) {
        if(comment !== null && comment !== '') {
            const icon = document.createElement("i")
            icon.className = "fas fa-comment"
            icon.style = "color: var(--primary-color); margin-left: 0.25rem;";
            let indexToUse = index === null ? store.getters.getHighlightList.length - 1 : index
            icon.setAttribute('id', 'icon-comment-' + indexToUse)
            const rangeElement = document.getElementById(this.highlightId(indexToUse, secondIndex))
            rangeElement.appendChild(icon);
        } else {
            if(document.getElementById('icon-comment-' + index) !== null) {
                const icon = document.getElementById('icon-comment-' + index);
                icon.remove();
            }
        }
    },
    deleteHighlight(highlightId) {
        store.getters.getHighlightList.forEach(element => {
            let id = highlightId.substring(highlightId.indexOf('-') + 1, highlightId.length);
            if (id.length === 1) {
                if(element.id === parseInt(highlightId.substring(highlightId.indexOf('-') + 1), 10)) {
                    const newNode = document.createTextNode(document.getElementById(highlightId).innerText);
                    document.getElementById(highlightId).parentNode.replaceChild(newNode, document.getElementById(highlightId));
                    store.commit("removeElementFromHighlightList", element);
                }
            }
            else {
                let i = 0;
                const baseIndex = id.substring(0, 1);
                while(document.getElementById(this.highlightId(baseIndex, i)) !== null) {
                    const newNode = document.createTextNode(document.getElementById(this.highlightId(baseIndex, i)).innerText);
                    document.getElementById(this.highlightId(baseIndex, i)).parentNode.replaceChild(newNode, document.getElementById(this.highlightId(baseIndex, i)));
                    i += 1;
                }
                if(element.id === parseInt(baseIndex, 10)) {
                    store.commit("removeElementFromHighlightList", element);
                }

            }

        })
    }
}