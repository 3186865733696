<template>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingVisitedPage">
      <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVisitedPage" aria-expanded="false" aria-controls="VisitedPage">
        {{ $t("visitedPageList") }}
      </button>
    </h2>
    <div id="collapseVisitedPage" class="accordion-collapse collapse" aria-labelledby="headingVisitedPage" data-bs-parent="#accordionTools">
      <div :class="accordionBodyClass">
        <table class="table table-striped align-middle" aria-label="VisitedPages">
          <thead>
          <tr>
            <th scope="col">{{ $t("page") }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="visitedPage in visitedPageList" :key="visitedPage">
            <td><router-link :to="{name: visitedPage}">{{ $t(visitedPage) }}</router-link></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faLink } from '@fortawesome/free-solid-svg-icons'

library.add({faTimes, faLink})

export default {
  name: "VisitedPageList",
  computed: {
    ...mapGetters([
      'getVisitedPageList', 'getTheme'
    ]),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
    visitedPageList() {
      return this.getVisitedPageList;
    },
    tableClass() {
      let theme = localStorage.getItem("theme");
      if(theme === 'dark') {
        return 'table table-dark table-striped align-middle';
      }
      return 'table table-striped align-middle';
    }
  },
  methods: {
    ...mapMutations([
      'removeElementFromVisitedPageList'
    ])
  }
}
</script>

<style scoped>

.table {
  text-align: center;
}

thead {
  background: rgba( var(--accordion-bg-color), 0.40);
  color: rgba(var(--on-background-color), 0.87);
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: rgba( var(--accordion-bg-color), 0.8);
  color: rgba(var(--on-background-color), 0.87);
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: rgba( var(--accordion-bg-color), 0.40);
  color: rgba(var(--on-background-color), 0.87);
}

</style>