<template>
  <div class="smallContent">
    <h1 class="content-title">{{ $t("home") }}</h1>
    <div>
      <p>
        PraedictIA est un mot inspiré du latin « praedict » qui signifie « prévoir ». Quel est l’avenir de
        l’intelligence artificielle (IA) ? Cette science, déjà bien présente dans notre vie de tous les jours, devrait
        connaître une croissance fulgurante au cours des prochaines années.
      </p>
      <p>
        Le livre interactif PraedictIA propose une expérience interactive accessible sur toutes les plateformes. Vous
        pouvez notamment surligner les passages qui vous intéressent et ajouter des notes en commentaires.
        Les thématiques de l’IA traitées sur le site sont présentées dans un format réduit pour mettre l’accent sur des
        outils et des démonstrations de concepts en temps réel, particulièrement dans la section « Traitement du langage
        naturel ».
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>

.smallContent {
  height: 90vh;
}

</style>
