<template>
  <div class="row g-0" >
    <font-awesome-icon icon="moon" class="col theme-icon"/>
    <label for="flexSwitchCheckChecked" class="col-10 element-label">
      {{ $t("darkTheme") }}
    </label>
    <span class="form-switch col">
      <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="darkMode" @click="reloadPage()">
    </span>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMoon } from '@fortawesome/free-solid-svg-icons'
import {mapGetters, mapMutations} from "vuex";

library.add(faMoon)

export default {
  name: "ThemePicker",
  data() {
    return {
      darkMode: false,
    }
  },
  computed: {
    ...mapGetters(['getTheme'])
  },
  methods: {
    ...mapMutations(['setTheme']),
    reloadPage() {
      location.reload();
    }
  },
  mounted() {
    // check for active theme
    let htmlElement = document.documentElement;
    if (this.getTheme === 'dark') {
      htmlElement.setAttribute('theme', 'dark')
      this.darkMode = true
      this.setTheme('dark')
    } else {
      htmlElement.setAttribute('theme', 'light');
      this.darkMode = false
      this.setTheme('light')
    }

  },
  watch: {
    darkMode: function () {
      // add/remove class to/from html tag
      let htmlElement = document.documentElement;
      if (this.darkMode) {
        this.setTheme('dark')
        htmlElement.setAttribute('theme', 'dark');
      } else {
        this.setTheme('light')
        htmlElement.setAttribute('theme', 'light');
      }
    }
  }
}
</script>

<style scoped>

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: rgba(0,0,0,.25);
}

.form-check-input:focus:not(:checked) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
}

.form-check-input:focus {
  border-color: rgba(0,0,0,.25);
  box-shadow: none;
}

.element-label {
  margin-left: 0.25rem;
}

.theme-icon {
  margin-left: -0.5rem !important;
}

.form-switch {
  padding-left: 2rem;
}

</style>