<template>
  <h5 class="mt-4">Des exemples de réponses apportées aux questions d'un texte</h5>
  <div class="accordion" id="accordionQuestionAnswering">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOneQuestionAnswering">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneQuestionAnswering" aria-expanded="false" aria-controls="collapseOneQuestionAnswering">
          Bert
        </button>
      </h2>
      <div id="collapseOneQuestionAnswering" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingOneQuestionAnswering" data-bs-parent="#accordionQuestionAnswering">
        <div class="accordion-body">
          <iframe src="https://www.pragnakalp.com/demos/BERT-NLP-QnA-Demo/" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwoQuestionAnswering">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoQuestionAnswering" aria-expanded="false" aria-controls="collapseTwoQuestionAnswering">
          KungFu AI
        </button>
      </h2>
      <div id="collapseTwoQuestionAnswering" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingTwoQuestionAnswering" data-bs-parent="#accordionQuestionAnswering">
        <div class="accordion-body">
          <iframe src="https://demo.kungfu.ai/demos/qa" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThreeAnswering">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeAnswering" aria-expanded="false" aria-controls="collapseThreeAnswering">
          Allen
        </button>
      </h2>
      <div id="collapseThreeAnswering" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingThreeAnswering" data-bs-parent="#accordionQuestionAnswering">
        <div class="accordion-body">
          <iframe src="https://modularqa-demo.apps.allenai.org/" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "questionAnswering",
  computed: {
    ...mapGetters(['getTheme']),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
  }
}
</script>

<style scoped>

</style>