<template>
  <h5 class="mt-4">Des exemples pour résumer un texte</h5>
  <div class="accordion" id="accordionSummarization">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOneSummarization">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneSummarization" aria-expanded="false" aria-controls="collapseOneSummarization">
          KungFu AI
        </button>
      </h2>
      <div id="collapseOneSummarization" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingOneSummarization" data-bs-parent="#accordionSummarization">
        <div class="accordion-body">
          <iframe src="https://demo.kungfu.ai/demos/summarization" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwoSummarization">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoSummarization" aria-expanded="false" aria-controls="collapseTwoSummarization">
          Smrzr
        </button>
      </h2>
      <div id="collapseTwoSummarization" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingTwoSummarization" data-bs-parent="#accordionSummarization">
        <div class="accordion-body">
          <iframe src="https://smrzr.io/" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "summarization",
  computed: {
    ...mapGetters(['getTheme']),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
  }
}
</script>

<style scoped>

</style>