import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Guide from '../views/Guide.vue'
import Tools from "../views/Tools.vue";
import BigData from "../views/content/BigData";
import DataAnalysis from "../views/content/DataAnalysis";
import DataGeneration from "../views/content/DataGeneration"
import DeepLearning from "../views/content/DeepLearning";
import ImageRecognition from "../views/content/ImageRecognition";
import InternetOfThings from "../views/content/InternetOfThings";
import MachineLearning from "../views/content/MachineLearning";
import NaturalLanguageProcessing from "../views/content/NaturalLanguageProcessing";
import NeuralNetwork from "../views/content/NeuralNetwork";
import VoiceRecognition from "../views/content/VoiceRecognition";

import ParentPage from '../views/content/ParentPage.vue'

/*import aIHistory from '../views/content/introduction/aIHistory.vue'
import mythsAndRealities from '../views/content/introduction/mythsAndRealities.vue'
import jobsInAI from "@/views/content/introduction/jobsInAI.vue";

import whatIsNLP from '../views/content/NLP/whatIsNLP.vue'
import howNLP from '../views/content/NLP/howNLP.vue'*/

/*import tts from "@/views/content/examples/tts.vue"
import stt from "@/views/content/examples/stt.vue"
import entityAnalysis from "@/views/content/examples/entityAndSentimentAnalysis.vue";
import textCompletion from "@/views/content/examples/textCompletion.vue"
import questionAnswering from '@/views/content/examples/questionAnswering'
import summarization from '@/views/content/examples/summarization'
import posTagging from '@/views/content/examples/posTagging'
import languageTranslation from "@/views/content/examples/languageTranslation";*/

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/guide',
    name: 'guide',
    component: Guide
  },
  {
    path: '/tools',
    name: 'tools',
    component: Tools
  },
  {
    path: '/',
    name: 'parentPage',
    component: ParentPage,
    children: [
      {
        path: 'bigData',
        name: 'bigData',
        component: BigData
      },
      {
        path: 'dataAnalysis',
        name: 'dataAnalysis',
        component: DataAnalysis
      },
      {
        path: 'dataGeneration',
        name: 'dataGeneration',
        component: DataGeneration
      },

      {
        path: 'deepLearning',
        name: 'deepLearning',
        component: DeepLearning
      },
      {
        path: 'imageRecognition',
        name: 'imageRecognition',
        component: ImageRecognition
      },
      {
        path: 'internetOfThings',
        name: 'internetOfThings',
        component: InternetOfThings
      },
      {
        path: 'machineLearning',
        name: 'machineLearning',
        component: MachineLearning
      },
      {
        path: 'naturalLanguageProcessing',
        name: 'naturalLanguageProcessing',
        component: NaturalLanguageProcessing
      },
      {
        path: 'neuralNetwork',
        name: 'neuralNetwork',
        component: NeuralNetwork
      },
      {
        path: 'voiceRecognition',
        name: 'voiceRecognition',
        component: VoiceRecognition
      },
      /*{
        path: 'tts',
        name: 'tts',
        component: tts
      },
      {
        path: 'stt',
        name: 'stt',
        component: stt
      },
      {
        path: 'entityAnalysis',
        name: 'entityAnalysis',
        component: entityAnalysis
      },
      {
        path: 'textCompletion',
        name: 'textCompletion',
        component: textCompletion
      },
      {
        path: 'questionAnswering',
        name: 'questionAnswering',
        component: questionAnswering
      },
      {
        path: 'summarization',
        name: 'summarization',
        component: summarization
      },
      {
        path: 'posTagging',
        name: 'posTagging',
        component: posTagging
      },
      {
        path: 'languageTranslation',
        name: 'languageTranslation',
        component: languageTranslation
      }*/
    ]
  },
  /*{
    path: '/introduction',
    name: 'introductionParentPage',
    component: ParentPage,
    children: [
      {
        path: 'mythsAndRealities',
        name: 'mythsAndRealities',
        component: mythsAndRealities
      },
      {
        path: 'aIHistory',
        name: 'aIHistory',
        component: aIHistory
      },
      {
        path: 'jobsInAI',
        name: 'jobsInAI',
        component: jobsInAI
      }
    ]
  },
  {
    path: '/NLP',
    name: 'nPLParentPage',
    component: ParentPage,
    children: [
      {
        path: 'whatIsNLP',
        name: 'whatIsNLP',
        component: whatIsNLP
      },
      {
        path: 'howNLP',
        name: 'howNLP',
        component: howNLP
      }
    ]
  },
  {
    path: '/examples',
    name: 'examplesParentPage',
    component: ParentPage,
    children: [
      {
        path: 'tts',
        name: 'tts',
        component: tts
      },
      {
        path: 'stt',
        name: 'stt',
        component: stt
      },
      {
        path: 'entityAnalysis',
        name: 'entityAnalysis',
        component: entityAnalysis
      },
      {
        path: 'textCompletion',
        name: 'textCompletion',
        component: textCompletion
      },
      {
        path: 'questionAnswering',
        name: 'questionAnswering',
        component: questionAnswering
      },
      {
        path: 'summarization',
        name: 'summarization',
        component: summarization
      },
      {
        path: 'posTagging',
        name: 'posTagging',
        component: posTagging
      },
      {
        path: 'languageTranslation',
        name: 'languageTranslation',
        component: languageTranslation
      }
    ]
  },*/
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach(to => {
  localStorage.setItem("route", to.path);
});

export default router
