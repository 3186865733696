<template>
  <h1 class="content-title"> {{ $t('dataGeneration') }} </h1>
  <div class="mb-4 mt-4">
    <p>
      Les modèles génératifs représentent une catégorie d’apprentissage machine : ils ont pour objectif de générer de
      nouvelles données suivant des règles et des conditions spécifiques. Une explication simplifiée serait que les
      modèles génératifs sont capables d’apprendre les caractéristiques communes d’une collection de données et ensuite,
      de générer des données similaires. Les dernières avancées ont fait en sorte que les données générées soient d’une
      qualité irréprochable et parfois, impossibles à distinguer des données réelles.
    </p>
    <p>
      Il existe plusieurs approches pour créer des modèles génératifs opérationnels, dont l’utilisation des réseaux de
      neurones antagonistes et génératifs (GANs ou Generative Adversarial Network), l’une des approches modernes les
      plus efficaces. Pour bien comprendre le fonctionnement d’un GAN, supposons que nous entraînions un réseau
      antagoniste génératif à générer des images synthétiques de portraits humains dont l’apparence se rapproche le plus
      possible de la réalité.
    </p>
    <p>
      Ce GAN serait principalement composé des éléments suivants :
    </p>
    <ul>
      <li>
        <strong>Une collection de données d’entraînement</strong> qui servira de référence pour le modèle (dans ce cas,
        une collection d’images de portraits humains réels);
      </li>
      <li>
        <strong>Un réseau de neurones génératif</strong> (Generative Neural Network) dont la morphologie est appropriée
        à la génération des données, contrairement aux réseau x de neurones adaptés à la classification, la régression,
        etc. Ces réseaux de neurones auront une couche de sortie permettant de produire une donnée résultante, et non
        une classe spécifique ou une valeur spécifique.
      </li>
    </ul>
    <p>
      La création, l’entraînement et la validation d’un modèle génératif se font traditionnellement par le biais des
      librairies de programmation dédiées à l’apprentissage machine :
    </p>
    <ul>
      <li>
        <strong><a href="https://www.tensorflow.org/?hl=fr" target="_blank">TensorFlow,</a></strong> l’une des librairies les plus utilisées en apprentissage machine et en
        apprentissage profond. Grâce à cette librairie et à son interface de programmation, les utilisateurs peuvent
        créer, entraîner, valider et tester différents types d’algorithmes d’apprentissage machine, incluant des modèles
        génératifs tels que GAN, VAE, etc.;
      </li>
      <li>
        <strong><a href="https://keras.io/" target="_blank">Keras,</a></strong> une librairie dédiée principalement à l’apprentissage profond pour concevoir et
        entraîner facilement des modèles génératifs qui se basent sur des réseaux de neurones tels que DeepGAN, DeepVAE,
        etc.;
      </li>
      <li>
        <strong><a href="https://poloclub.github.io/ganlab/" target="_blank">GanLab,</a></strong> un outil à source libre (open source) permettant la création des réseaux de neurones
        génératifs et la visualisation de leur exécution lors des phases d’entraînement et de validation.
      </li>
    </ul>
  </div>
  <div>
    <text-completion/>
  </div>
</template>

<script>
import textCompletion from "@/components/Examples/textCompletion";

export default {
  name: "DataGeneration",
  components: {
    textCompletion
  }
}
</script>

<style scoped>

</style>