<template>
  <h5 class="mt-4">Des exemples de traducteur de texte</h5>
  <div class="accordion mb-4" id="accordionLanguageTranslation">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwoLanguageTranslation">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoLanguageTranslation" aria-expanded="false" aria-controls="collapseTwoLanguageTranslation">
          Microsoft Bing
        </button>
      </h2>
      <div id="collapseTwoLanguageTranslation" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingTwoLanguageTranslation" data-bs-parent="#accordionLanguageTranslation">
        <div class="accordion-body">
          <iframe src="https://www.bing.com/Translator?toWww=1&redig=50956A7115134A7483B23DBAE3BCA7BE" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingFourLanguageTranslation">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourLanguageTranslation" aria-expanded="false" aria-controls="collapseFourLanguageTranslation">
          Amazon AWS
        </button>
      </h2>
      <div id="collapseFourLanguageTranslation" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingFourLanguageTranslation" data-bs-parent="#accordionLanguageTranslation">
        <div class="accordion-body">
          <iframe src="https://ai-service-demos.go-aws.com/translate" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "languageTranslation",
  computed: {
    ...mapGetters(['getTheme']),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
  },
}
</script>

<style scoped>

</style>