<template>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingBookmark">
      <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBookmark" aria-expanded="false" aria-controls="collapseBookmark">
        {{ $t("bookmarkList") }}
      </button>
    </h2>
    <div id="collapseBookmark" class="accordion-collapse collapse" aria-labelledby="headingBookmark" data-bs-parent="#accordionTools">
      <div :class="accordionBodyClass">
        <table class="table table-striped align-middle" aria-label="Bookmarks" v-if="bookmarkList.length > 0">
          <thead>
          <tr>
            <th scope="col">{{ $t("page") }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="bookmark in bookmarkList" :key="bookmark">
            <td><router-link :to="{name: bookmark}">{{ $t(bookmark) }}</router-link></td>
            <td>
              <button class="btn" @click="deleteBookmark(bookmark)"><font-awesome-icon icon="times" style="color: var(--error-color);"/></button>
            </td>
          </tr>
          </tbody>
        </table>
        <p v-else>Vous n'avez pas encore enregistré de favoris.</p>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapMutations} from "vuex";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faLink } from '@fortawesome/free-solid-svg-icons'

library.add({faTimes, faLink})

export default {
  name: "BookmarkList",
  computed: {
    ...mapGetters([
      'getBookmarkList', 'getTheme'
    ]),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
    bookmarkList() {
      return this.getBookmarkList;
    },
    tableClass() {
      let theme = localStorage.getItem("theme");
      if(theme === 'dark') {
        return 'table table-dark table-striped align-middle';
      }
      return 'table table-striped align-middle';
    }
  },
  methods: {
    ...mapMutations([
      'removeElementFromBookmarkList'
    ]),
    deleteBookmark(route) {
      this.removeElementFromBookmarkList(route);
    }
  }
}
</script>

<style scoped>

.table {
  text-align: center;
}

thead {
  background: rgba( var(--accordion-bg-color), 0.40);
  color: rgba(var(--on-background-color), 0.87);
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: rgba( var(--accordion-bg-color), 0.8);
  color: rgba(var(--on-background-color), 0.87);
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: rgba( var(--accordion-bg-color), 0.40);
  color: rgba(var(--on-background-color), 0.87);
}

</style>