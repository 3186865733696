<template>
  <h1 class="content-title">{{ $t('dataAnalysis') }}</h1>
  <div class="mb-4 mt-4">
    <p>
      L’analyse des données (parfois nommée « analytique ») réfère à l’ensemble des activités qui se rapportent à
      l’exploration, l’exploitation, l’interprétation et la communication des données, et à leurs dérivés (tendances,
      corrélations, etc.).
    </p>
    <p>
      Elle touche tous les secteurs d’activités (marketing, finance, santé, manufacture, etc.) et repose sur un ensemble
      de technologies (incluant les différentes techniques liées à l’intelligence artificielle et à l’apprentissage
      machine) pour explorer les données historiques, en extraire des tendances et en déduire des corrélations et des
      prédictions.
    </p>
    <p>
      Il existe plusieurs façons de faire de l’analyse des données, les plus communes étant : l’analyse descriptive,
      l’analyse diagnostique, l’analyse prédictive et l’analyse prospective. Ces catégories sont généralement définies
      suivant l’usage des données en question. Par exemple, les techniques d’analyse prédictive serviront à prédire des
      tendances futures et des phénomènes inconnus d’après l’exploitation des données historiques.
    </p>
    <p>
      Les techniques liées à l’intelligence artificielle et à l’apprentissage machine sont de plus en plus utilisées
      pour répondre aux besoins analytiques. La capacité qu’ont les algorithmes d’intelligence artificielle à détecter
      les tendances et les corrélations leur permettent d’occuper une place privilégiée dans le monde de l’analyse des
      données.
    </p>
    <p>
      De nombreux outils intuitifs permettent aux utilisateurs de faire de l’analyse des données avancées avec des
      interfaces graphiques, sans avoir de connaissances spécifiques en programmation :
    </p>
    <ul>
      <li>
        Outils d’analyse des données inclus dans les services infonuagiques, tels :
        <a href="https://cloud.google.com/solutions/smart-analytics" target="_blank">Google Cloud Data Analytics</a>,
        <a href="https://azure.microsoft.com/en-us/solutions/big-data/#overview" target="_blank">Microsoft Azure Cloud Analytics</a>,
        <a href="https://aws.amazon.com/fr/kinesis/data-analytics/" target="_blank">Amazon Kinesis</a> et
        <a href="https://www.ibm.com/ca-fr/products/cloud-pak-for-data" target="_blank">IBM Cloud Pak for Data</a>;
      </li>
      <li>
        Outils ETL, tels :
        <a href="https://www.talend.com/fr/" target="_blank">Talend</a>,
        <a href="https://www.mulesoft.com/fr/" target="_blank">MuleSoft</a>,
        <a href="https://www.microsoft.com/fr-ca/sql-server/sql-server-downloads" target="_blank">Microsoft SQL (SSIS)</a>,
        <a href="https://kafka.apache.org/intro" target="_blank">Apache Kafka</a>,
        <a href="https://segment.com/" target="_blank">Segment</a> et
        <a href="https://www.xplenty.com/" target="_blank">Xplenty</a>;
      </li>
      <li>
        Outils dédiés à l’exploration des données, tels :
        <a href="https://www.tableau.com/fr-fr" target="_blank">Tableau Software</a>,
        <a href="https://www.microstrategy.com/fr" target="_blank">MicroStrategy</a>,
        <a href="https://www.qlik.com/fr-fr/products/qlik-sense" target="_blank">QlikView</a>,
        <a href="https://powerbi.microsoft.com/fr-ca/" target="_blank">Microsoft Power BI</a> et
        <a href="https://www.alteryx.com/fr/" target="_blank">Alteryx</a>.
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "DataAnalysis"
}
</script>

<style scoped>

</style>