<template>
  <div @mouseup="handleMouseUpAndTouchEnd($event, true)" @touchend="handleMouseUpAndTouchEnd($event, true)">
    <TopMenu @toggle-side-bar="moveContentForMenu()" class="top-menu"></TopMenu>
    <SideBar @toggle-side-bar="moveContentForMenu()"></SideBar>
  </div>
  <div :class="contentClass" id="main-content">
    <div class="left-line col col-xl-2 col-lg-2"></div>
    <div class="col col-xl-8 col-lg-8 col-12">
      <Bookmark @mouseup="handleMouseUpAndTouchEnd($event, true)" @touchend="handleMouseUpAndTouchEnd($event, true)"></Bookmark>
      <SideCommentBar @toggle-side-comment="moveContentForComment()"></SideCommentBar>
      <HighlightTooltip :tool-tip-style="toolTipStyle" :highlight-id="highlightId" @close-tooltip="closeTooltip()" :tool-tip-position="toolTipPosition"></HighlightTooltip>
      <div @mouseup="handleMouseUpAndTouchEnd($event)" @touchend="handleMouseUpAndTouchEnd($event)" @click="onHighlightSelect($event)" class="row">
        <router-view class="content"/>
      </div>
    </div>
    <div class="right-line col col-xl-2 col-lg-2 col-sm-0"></div>
  </div>
</template>

<script>
import SideBar from '/src/components/SideBar'
import TopMenu from '/src/components/TopMenu'
import Bookmark from "/src/components/Bookmark";
import HighlightTooltip from "/src/components/highlightTooltip";
import SideCommentBar from "/src/components/SideCommentBar"
import {mapActions, mapGetters, mapMutations} from "vuex";
import highlightHelper from "/src/helper/highlightHelper";
import rangy from "rangy/lib/rangy-serializer";

export default {
  components: {
    SideBar,
    TopMenu,
    Bookmark,
    HighlightTooltip,
    SideCommentBar
  },
  data() {
    return {
      sideMenuExpanded: false,
      sideCommentExpanded: false,
      toolTipStyle: {},
      highlightId: null,
      toolTipPosition: null
    }
  },
  methods: {
    ...mapActions(["fetchBookmarks", "fetchHighlights", "fetchVisitedPages", "fetchSideComments", "fetchTheme"]),
    ...mapMutations(["toggleSideMenuState", "addElementToVisitedPageList"]),
    moveContentForMenu() {
      if(!window.matchMedia('(max-width: 500px)').matches) {
        this.sideMenuExpanded = !this.sideMenuExpanded;
      }
    },
    moveContentForComment() {
      if(!window.matchMedia('(max-width: 700px)').matches) {
        this.sideCommentExpanded = !this.sideCommentExpanded;
      }
    },
    closeTooltip() {
      this.toolTipStyle = {};
      this.highlightId = null;
    },
    getCurrentPageHighlights() {
      this.getHighlightList.forEach(highlight => {
        if (highlight.route === this.$route.name) {
          highlightHelper.highlight(this.$route.name, highlight.color, highlight.comment, highlight.id, rangy.deserializeRange(highlight.range), false)
        }
      })
    },
    onHighlightSelect(e) {
      if (typeof e.target.className === 'string' && e.target.className.includes('highlight')) {
        this.toolTipStyle = this.positionToolTip(document.getElementById(e.target.id));
      }
    },
    addRangeWithTimeout(selection, range) {
      return new Promise(function (resolve) {
        setTimeout(function () {
          selection.addRange(range)
          resolve();
        }, 100);
      });
    },
    handleMouseUpAndTouchEnd(e, forCLosingTooltip = false) {
      this.handleOffCanvasClosing(e);
      this.handleSelection(e, forCLosingTooltip);
    },
    handleOffCanvasClosing(e) {
      if(this.getSideMenuState && window.matchMedia('(max-width: 500px)').matches) {
        if(e.target.id === "offcanvasButton" || e.target.id === "offcanvasButton") {
          this.toggleSideMenuState();
        } else if(e.currentTarget.children[0].id === 'navbar') {
          // doNothing
        } else {
          window.$("#offcanvasMainMenu").offcanvas('toggle');
          this.moveContentForMenu();
          this.toggleSideMenuState();
        }
      }
    },
    handleSelection(e, forCLosingTooltip = false) {
      if (typeof e.target.className === 'string' && !e.target.className.includes('highlight')) {
        let S = getSelection();
        if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent) && !S.isCollapsed) {
          let r = S.getRangeAt(0);
          S.removeAllRanges();
          this.addRangeWithTimeout(S, r).then(() => {
                setTimeout(this.showToolTip(forCLosingTooltip), 2);
              }
          );
        } else {
          setTimeout(this.showToolTip(forCLosingTooltip), 20);
        }
      }
    },
    showToolTip(forCLosingTooltip) {
      this.highlightId = null;
      let toolTipLocStyle = {};
      if (!forCLosingTooltip && document.getSelection() && document.getSelection().toString() !== ''
          && document.getSelection() && document.getSelection().toString() !== ' ') {
        toolTipLocStyle = this.positionToolTip(document.getSelection());
      }
      this.toolTipStyle = toolTipLocStyle;
    },
    positionToolTip(selection) {
      if(selection.id !== undefined) {
        this.highlightId = selection.id
      }
      const scrollPosition = this.getCurrentScrollPosition();
      let selectionRange = null;
      if (selection instanceof HTMLElement) {
        selectionRange = selection.getBoundingClientRect();
      }
      else {
        selectionRange = selection.getRangeAt(0).getBoundingClientRect();
      }
      let top, middleRangePosition;
      if(((selectionRange.left + selectionRange.right) / 2) <= 115) {
        this.toolTipPosition = 'right'
        top = selectionRange.top - 15;
        middleRangePosition = ((selectionRange.left + selectionRange.right) / 2) + 20;
      } else if(window.innerWidth - ((selectionRange.left + selectionRange.right) / 2) < 115) {
        this.toolTipPosition = 'left'
        top = selectionRange.top - 15;
        middleRangePosition = ((selectionRange.left + selectionRange.right) / 2) - 220;
      } else {
        this.toolTipPosition = 'top'
        top = selectionRange.top - 60;
        middleRangePosition = ((selectionRange.left + selectionRange.right) / 2) - 93;
      }
      return {
        top: top + scrollPosition,
        left: middleRangePosition,
        opacity: 1
      }
    },
    getCurrentScrollPosition() {
      return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement
          || document.body.parentNode || document.body).scrollTop;
    },
    handleResize() {
      if(window.innerWidth <= 800) {
        this.sideCommentExpanded = false;
        if(window.innerWidth <= 500) {
          this.sideMenuExpanded = false;
        } else if(this.sideMenuExpanded && this.getSideMenuState) {
          this.sideMenuExpanded = true;
        }
      } else if(this.sideMenuExpanded && this.getSideCommentState) {
        this.sideCommentExpanded = true
      }
    },
  },
  computed: {
    ...mapGetters([
      'getHighlightList', 'getSideMenuState', 'getSideCommentState', 'getTheme'
    ]),
    contentClass() {
      if(this.sideMenuExpanded) {
        if(this.getTheme === 'dark') {
          return 'main-content-dark app-background side-menu-expanded row';
        }
        return 'main-content-light app-background side-menu-expanded row';
      } else if(this.sideCommentExpanded) {
        if(this.getTheme === 'dark') {
          return 'main-content-dark app-background side-comment-expanded row';
        }
        return 'main-content-light app-background side-comment-expanded row';
      }
      if(this.getTheme === 'dark') {
        return 'main-content-dark app-background row';
      }
      return 'main-content-light app-background row';
    },
  },
  created() {
    let locale = localStorage.getItem("locale");
    let lastRoute = localStorage.getItem("route");

    if(lastRoute) {
      this.$router.push(lastRoute)
    }

    if (locale === 'en') {
      this.$i18n.locale = 'en';
    } else {
      localStorage.setItem("locale", 'fr');
      this.$i18n.locale = 'fr';
    }

    window.addEventListener('resize', this.handleResize);
    this.fetchBookmarks();
    this.fetchVisitedPages();
    this.fetchHighlights();
    this.fetchSideComments();
    this.fetchTheme();
    this.handleResize();
  },
  mounted() {
    if(/(Android)/g.test(navigator.userAgent)) {
      let event;
      document.addEventListener('touchstart', (e) => {
        event = e;
      });
      document.addEventListener('selectionchange', () => {
        if(!getSelection().isCollapsed) {
          clearTimeout()
          setTimeout(this.handleMouseUpAndTouchEnd(event), 500)
        }
      });
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    $route() {
      this.addElementToVisitedPageList(this.$route.name);
      setTimeout(this.getCurrentPageHighlights, 20);
    },
    getSideMenuState(val) {
      if(val) {
        this.sideCommentExpanded = false;
      }
    },
    getSideCommentState(val) {
      if(val) {
        this.sideMenuExpanded = false;
      }
    }
  }
}
</script>

<style>

.side-menu-expanded {
  margin-left: 250px !important;
}

.side-comment-expanded {
  margin-right: 400px !important;
}

.top-menu {
  text-align: center;
}

.left-line {
  top: 0;
}

.right-line {
  top: 0;
}

</style>
