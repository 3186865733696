<template>
  <h5 class="mt-4">Un exemple d'analyse des sentiments</h5>
  <div class="accordion" id="accordionEntityAnalysis">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThreeEntityAnalysis">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeEntityAnalysis" aria-expanded="false" aria-controls="collapseThreeEntityAnalysis">
          Amazon AWS entity and sentiment analysis
        </button>
      </h2>
      <div id="collapseThreeEntityAnalysis" class="accordion-collapse collapse" :class="accordionBodyClass" aria-labelledby="headingThreeEntityAnalysis" data-bs-parent="#accordionEntityAnalysis">
        <div class="accordion-body">
          <iframe src="https://ai-service-demos.go-aws.com/comprehend" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "nlu",
  computed: {
    ...mapGetters(['getTheme']),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
  },
}
</script>

<style scoped>

</style>