<template>
  <h1 class="content-title"> {{ $t('deepLearning') }} </h1>
  <div class="mb-4 mt-4">
    <p>
      L’apprentissage profond (Deep Learning), l’une des sous-disciplines les plus prometteuses dans le domaine de
      l’apprentissage machine, réfère aux différentes techniques d’apprentissage machine qui se font par le biais des
      réseaux de neurones artificiels profonds (Deep Neural Network). Elle a révolutionné les mondes de l’intelligence
      artificielle et de l’apprentissage machine. La possibilité de créer des réseaux de neurones artificiels si
      performants a procuré de nombreuses opportunités pour tous les segments de l’économie : divertissement, marketing,
      médecine, finance, manufacture, etc.
    </p>
    <p>
      Les réseaux de neurones profonds ont démontré une réelle capacité à extraire de la connaissance quel que soit le
      support de données : images, vidéos , échantillons de sons, données transactionnelles, etc. En résumé, un réseau
      de neurones artificiels profond est constitué :
    </p>
    <ul>
      <li>
        D’une couche d’entrée composée d’un ensemble de neurones qui favoriseront la propagation des informations dans
        le réseau neuronal;
      </li>
      <li>
        D’une couche de sortie formée d’un ensemble de neurones représentant les différentes classes de résultat;
      </li>
      <li>
        De couches intermédiaires servant à traiter l’information propagée dans le réseau de neurones pour capturer les
        caractéristiques de son apprentissage.
      </li>
    </ul>
    <p>
      La création de réseaux de neurones artificiels passe traditionnellement par une étape de programmation qui
      consiste à implémenter les différents mécanismes rattachés à la création, l’entraînement, la validation et
      l’exécution des réseaux de neurones profonds. Cela se fait à l’aide des outils suivants :
    </p>
    <ul>
      <li>
        Librairies, telles que
        <a href="https://www.tensorflow.org/?hl=fr" target="_blank">Tensorflow,</a>
        <a href="https://pytorch.org/" target="_blank">PyTorch</a>,
        <a href="https://keras.io/" target="_blank">Keras</a>, etc.;
      </li>
      <li>
        Interfaces de programmation, telles que
        <a href="https://www.python.org/" target="_blank">Python</a>,
        <a href="https://www.javascript.com/" target="_blank">Javascript</a>, ou autres;
      </li>
      <li>
        Plateformes, telles que
        <a href="https://www.datarobot.com/" target="_blank">DataRobot</a>,
        <a href="https://www.dataiku.com/" target="_blank">Dataiku</a>, etc.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DeepLearning"
}
</script>

<style scoped>

</style>