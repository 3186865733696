<template>
  <h5 class="mt-4">Un exemple de génération de texte</h5>
  <div class="accordion mb-4" id="accordionTextCompletion">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwoCompletion">
        <button class="accordion-button collapsed" :class="accordionButtonClass" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoCompletion" aria-expanded="false" aria-controls="collapseTwoCompletion">
          Transformer
        </button>
      </h2>
      <div id="collapseTwoCompletion" class="accordion-collapse collapse" aria-labelledby="headingTwoCompletion" data-bs-parent="#accordionTextCompletion">
        <div class="accordion-body" :class="accordionBodyClass">
          <iframe src="https://transformer.huggingface.co/doc/distil-gpt2" width="100%" height="400"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "textCompletion",
  computed: {
    ...mapGetters(['getTheme']),
    accordionButtonClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-button-dark'
      }
      return 'accordion-button-light'
    },
    accordionBodyClass() {
      if(this.getTheme === 'dark') {
        return 'accordion-body scrollable-table accordion-body-dark'
      }
      return 'accordion-body scrollable-table accordion-body-light'
    },
  }
}
</script>

<style scoped>

</style>