<template>
  <nav id="navbar" class="navbar navbar-expand-md navbar-dark">
    <div class="container-fluid">
      <button id="offcanvasButton" class="btn toggleBtn" @click="moveContentAndToggleOffCanvas">
        <font-awesome-icon id="offcanvasIcon" icon="bars" style="color: rgba(var(--on-background-color), 0.87);" size="2x"/>
      </button>
      <div class="navbar-brand">
        <img :class="logoStyle" alt="Vue logo" src="../assets/img/PraedictIA-logo.png">
      </div>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li>
            <router-link class="nav-link" to="/"><font-awesome-icon icon="home" style="color: rgba(var(--on-background-color), 0.87);"/> {{ $t("home") }}</router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/guide"><font-awesome-icon icon="info-circle" style="color: rgba(var(--on-background-color), 0.87);"/> {{ $t("guide") }}</router-link>
          </li>
          <li>
            <router-link class="nav-link" to="/tools"><font-awesome-icon icon="toolbox" style="color: rgba(var(--on-background-color), 0.87);"/> {{ $t("tools") }}</router-link>
          </li>
        </ul>
      </div>
      <form class="d-flex">
        <Settings></Settings>
      </form>
    </div>
  </nav>
</template>

<script>
import Settings from "@/components/Settings/Settings";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { faBars, faCog, faHome, faInfoCircle, faSearch, faToolbox } from '@fortawesome/free-solid-svg-icons'
import {mapGetters, mapMutations} from "vuex";

library.add(faBars)
library.add(faCog)
library.add(faHome)
library.add(faFontAwesome)
library.add(faHome)
library.add(faInfoCircle)
library.add(faSearch)
library.add(faToolbox)

export default {
  name: "TopMenu",
  components: {
    Settings
  },
  computed: {
    ...mapGetters(['getSideCommentState', 'getSideMenuState', 'getTheme']),
    logoStyle() {
      if (this.getTheme === 'light') {
        return 'logo shadowed'
      }
      return 'logo'
    }
  },
  methods: {
    ...mapMutations([
      'toggleSideMenuState', 'toggleSideCommentState'
    ]),
    moveContentAndToggleOffCanvas() {
      if(this.getSideCommentState) {
        this.toggleSideCommentState();
        window.$("#offcanvasRight").offcanvas('toggle');
      }
      if(!this.getSideMenuState) {
        window.$("#offcanvasMainMenu").offcanvas('toggle');
      } else {
        if(document.getElementById("offcanvasMainMenu").className.includes("show")) {
          window.$("#offcanvasMainMenu").offcanvas('toggle');
        } else if(document.getElementById("offcanvasContentTable").className.includes("show")) {
          window.$("#offcanvasContentTable").offcanvas('toggle');
        }
      }
      this.$emit('toggleSideBar');
      this.toggleSideMenuState();
    },
    goTo(route) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-control {
   border: 1px solid var(--bg-color-09dp) !important;
  }
  .search-btn {
    background-color: var(--bg-color-09dp);
    color: var(--on-secondary-color);
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(var(--on-background-color), 0.87) !important;
  }
  .navbar-brand {
    color: rgba(var(--on-background-color), 0.87) !important;
  }
  .toggleBtn {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .brandIon {
    margin-right: 0.25rem;
  }
  .navbar {
    min-height: 70px;
    width: 100%;
    top: 0;
    position: absolute;
    border-bottom: 1px var(--bg-color-09dp) solid;
  }
  .form-control {
    background-color: var(--bg-color);
    border: 1px solid rgba(var(--on-background-color), 0.87);
  }
  @media only screen and (max-width: 992px) {
    .mb-2 {
      margin-bottom: 0 !important;
    }
  }
  /*Needed for sticky navbar*/
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .logo {
    width: 115px;

  }
  .shadowed {
    margin-bottom: 0.25rem;
    -webkit-filter: drop-shadow(2px 2px 2px rgba(var(--on-background-color), 0.87));
    filter: drop-shadow(2px 2px 2px rgba(var(--on-background-color), 0.87));
  }

</style>