<template>
  <h1 class="content-title"> {{ $t('imageRecognition') }} </h1>
  <div class="mb-4 mt-4">
    <p>
      La vision par ordinateur a pour but de doter les ordinateurs du sens de la « vue », pour leur permettre l’analyse,
      le traitement, l’identification et la reconnaissance du contenu à partir de données visuelles telles que des
      images, des vidéos, etc. L’exemple le plus simple est le lecteur de code-barres qui capte, à l’aide d’un lecteur
      optique, une série de lignes noires et blanches, l’interprète en une suite de chiffres, puis l’associe à la
      description du produit et à son prix entre autres.
    </p>
    <p>
      Nous sommes cependant en droit d’espérer davantage de la vision par ordinateur, compte tenu de la multitude
      d’informations visuelles reçues au quotidien, notamment via les réseaux sociaux, chaque image étant infiniment
      plus complexe qu’une série de lignes noires et blanches. Grâce à la reconnaissance d’images, l’intelligence
      artificielle entre en jeu pour permettre aux ordinateurs non seulement de capter des images, mais aussi de les
      déchiffrer, de les interpréter et d’en comprendre le contenu.
    </p>
    <p>
      L’objectif de la reconnaissance d’images est d’imiter toute la capacité du système visuel humain, y compris sa
      capacité d’apprentissage. Fondamentalement, la reconnaissance d’images tend à identifier le contenu d’une image ou
      d’un flux vidéo et à catégoriser les objets qui s’y trouvent. Elle fonctionne grâce à des algorithmes qui
      permettent de localiser des motifs dans les images et de les associer à des objets.
    </p>
    <p>
      Le traitement se fait au niveau des pixels, les unités fondamentales de l’image numérique. Lorsque l’image
      contient une quantité trop grande de pixels, on peut avoir recours aux réseaux neuronaux ; il s’agit
      d’applications informatiques constituées de « neurones » organisés en différentes couches successives
      interreliées, un peu comme la façon dont les neurones sont organisés dans le système nerveux central.
    </p>
    <p>
      Les plus gros joueurs dans le domaine de la reconnaissance d’images offrent des plateformes de programmation
      d’application (API) permettant de mettre à profit leurs outils de reconnaissance d’images. Il ne s’agit toutefois
      pas nécessairement de services « clé en main » pour des applications très précises. Voici une liste des
      principales plateformes et une description des tâches pouvant être réalisées :
    </p>
    <ul>
      <li>
        <a href="https://cloud.google.com/vision" target="_blank">Cloud Vision</a> et
        <a href="https://aws.amazon.com/fr/machine-learning/automl/" target="_blank">AutoML de Google</a>;
      </li>
      <li>
        <a href="https://aws.amazon.com/fr/rekognition/?blog-cards.sort-by=item.additionalFields.createdDate&blog-cards.sort-order=desc" target="_blank">Rekognition d’Amazon</a>;
      </li>
      <li>
        <a href="https://www.ibm.com/ca-fr/products/maximo/remote-monitoring" target="_blank">Watson Visual Recognition d’IBM</a>;
      </li>
      <li>
        <a href="https://azure.microsoft.com/fr-ca/services/cognitive-services/face/#overview" target="_blank">Face API</a>,
        <a href="https://azure.microsoft.com/en-us/services/cognitive-services/computer-vision/#overview" target="_blank">Computer Vision API</a> et
        <a href="https://azure.microsoft.com/fr-fr/services/form-recognizer/" target="_blank">Form Recognizer de Microsoft</a>;
      </li>
      <li>
        <a href="https://www.clarifai.com/">Clarifai</a>,
        <a href="https://opencv.org/">OpenCV</a>.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ImageRecognition"
}
</script>

<style scoped>

</style>